<!-- @format -->
<script lang="ts">
  import { onMount } from "svelte";
  import { getFragmentConfigOr } from "~/components/field-utils";
  export let fragment;
  const bgcolor = getFragmentConfigOr("bodyBackgroundColor", fragment, "#000");
  const headerColor = getFragmentConfigOr("headerColor", fragment, "#000");
  const footerLogoColor = getFragmentConfigOr("footerLogoColor", fragment, "#000");

  onMount(() => {
    const root: HTMLElement | null = document.querySelector(":root");
    if (root)
      root.style.cssText = `
        --body : ${bgcolor}; 
        --headerColor : ${headerColor}; 
        --footer-logo-color : ${footerLogoColor};
      `;
  });
</script>
